import React, { useState } from "react";
import { MDBContainer, MDBBtn } from "mdb-react-ui-kit";
import { PhotoAlbum } from "react-photo-album";

function Gallery({ data, id, index, itemsPerPage = 10 }) {
  const [currentPage, setCurrentPage] = useState(1);

  const showMoreItems = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const photoLink = (image) => `${image}`;

  const photos = data.slice(startIndex, endIndex).map((photo) => ({
    src: photoLink(photo.image, 960, 720),
    width: 960,
    height: 720,
    alt: photo.title,
  }));

  const totalPages = Math.ceil(data.length / itemsPerPage);

  return (
    <div id={id}>
      <div className="py-5" style={{ backgroundColor: "#ffd400" }}>
        <div className="m-5 text-dark text-center">
          <MDBContainer>
            <h2 className="fw-bold mb-2">GALLERY</h2>
            <p>
              "Here are some of our recent projects. We are proud of our work"
            </p>
          </MDBContainer>

          <PhotoAlbum
            layout="columns"
            photos={photos}
            columns={(containerWidth) => {
              if (containerWidth < 400) return 2;
              if (containerWidth < 800) return 3;
              return 4;
            }}
          />

          <div>
            {[...Array(totalPages)].map((_, page) => (
              <MDBBtn
                key={page}
                className="mx-2 my-3"
                color="dark"
                onClick={() => showMoreItems(page + 1)}
                active={currentPage === page + 1}
              >
                {page + 1}
              </MDBBtn>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
